import { useState } from "react";
import styles from "./Cart.module.scss";
import {
  Badge,
  Button,
  ButtonGroup,
  Loader,
  Text,
  useAuth,
} from "@eventsquare/uikit";
import {
  RiPencilFill,
  RiDeleteBinLine,
  RiSofaLine,
  RiShoppingCartLine,
  RiSettings3Line,
} from "@remixicon/react";
import classNames from "classnames/bind";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { formatCurrency, formatShowDate } from "@lib/helpers";

import { ChannelStep } from "@type/channel";
import {
  ActiveChannelCartType,
  ChannelCart,
  ChannelCartType,
  ChannelCartTypeShow,
} from "@type/channelCart";

import { Timer } from "@components/Timer/Timer";
import { CartExpirationModal } from "@components/modals/CartExpirationModal/CartExpirationModal";
import { SaveCartModal } from "@components/modals/SaveCartModal/SaveCartModal";
import { ChannelsContext } from "@components/ChannelsWrapper";
import { EditTypeModal } from "@components/modals/EditTypeModal/EditTypeModal";
import { DeleteTypeFromCartModal } from "@components/modals/DeleteTypeFromCartModal/DeleteTypeFromCartModal";
import { Currency } from "@type/currency";

interface CartProps {
  step: ChannelStep;
  setStep: (step: ChannelStep) => void;
  setActiveShowId: (id: string | null) => void;
  setDeleteCart: (
    cart: { cartid: string; saved_at: string | null } | null
  ) => void;
}

const cx = classNames.bind(styles);

export const Cart = (props: CartProps) => {
  const { step, setStep, setActiveShowId, setDeleteCart } = props;
  const { cart, cartIsBeingModified, setSeatmapTypeInCartToEdit } =
    useOutletContext<ChannelsContext>();

  const navigate = useNavigate();
  const { t } = useTranslation("components/cart");

  const [isCartOpen, setIsCartOpen] = useState(false);
  const [activeType, setActiveType] = useState<ActiveChannelCartType | null>(
    null
  );
  const [deleteType, setDeleteType] = useState<ChannelCartType | null>(null);
  const [saveCart, setSaveCart] = useState<ChannelCart | null>(null);
  const [showExpiredCartModal, setShowExpiredCartModal] = useState(false);

  const { user, account } = useAuth();

  const toggleCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const cartClass = cx("cart", {
    "cart--open": isCartOpen,
  });

  const cartFooterClass = cx("cart__footer", {
    "cart__footer--inactive": step === "checkout" || step === "confirmation",
  });

  const getShowName = (show: ChannelCartTypeShow) => {
    if (show.name && show.date.start) {
      return (
        show.name +
        " - " +
        formatShowDate(show.date.start, false, user?.language ?? "nl")
      );
    }

    if (show.name) {
      return show.name;
    }

    if (show.date.start) {
      return formatShowDate(
        show.date.start,
        show.date.hide_hours,
        user?.language ?? "nl"
      );
    }

    return show.id;
  };

  const isCartEmpty = (cart: ChannelCart) => {
    return cart.items.types.length <= 0;
  };

  if (!cart) {
    return (
      <div className={cartClass}>
        <div className={styles.cart__loader}>
          <Loader size="medium" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={cartClass}>
        <div className={styles.cart__header}>
          <div>
            <Text variant="h4" noMargin>
              {t("title")}{" "}
              {cart.items.types.length >= 1 && (
                <Badge
                  content={cart.items.types.reduce((acc, type) => {
                    return acc + type.quantity;
                  }, 0)}
                  size="small"
                />
              )}
            </Text>
            {cart.reference && (
              <Text variant="h6" noMargin>
                {cart.reference}
              </Text>
            )}
          </div>
          <Timer setShowExpiredCartModal={setShowExpiredCartModal} />
          {cart.saved_at && (
            <div>
              <Button
                size="tiny"
                icon
                variant="outline"
                onClick={
                  isCartEmpty(cart) ? undefined : () => setSaveCart(cart)
                }
              >
                <RiSettings3Line />
              </Button>
            </div>
          )}
        </div>
        <div className={styles.cart__main}>
          {cartIsBeingModified && (
            <div className={styles.cart__loader}>
              <Loader size="medium" />
            </div>
          )}
          {!cartIsBeingModified && (
            <div>
              {cart.items.types.length === 0 && (
                <Text variant="p">{t("empty_cart")}</Text>
              )}
              {cart.items.types.length > 0 && (
                <ul className={styles.cart__list}>
                  {cart.items.types.map((type, i) => {
                    return (
                      <li
                        className={styles.cart__listItem}
                        key={`${i}_${type.id}`}
                      >
                        <div>
                          <Text variant="h6" component="p" noMargin>
                            {`${type.quantity} ×`}
                          </Text>
                        </div>
                        <div>
                          <Text variant="h6" component="p" noMargin>
                            {type.name}
                          </Text>

                          {type.show && (
                            <Text variant="p" noMargin>
                              {getShowName(type.show)}
                            </Text>
                          )}
                          <Text variant="small" noMargin>
                            {type.price
                              ? formatCurrency(
                                  type.price,
                                  account?.currency as Currency
                                )
                              : t("free")}
                          </Text>
                          {Array.isArray(type.seatmap?.places) &&
                            type.seatmap.places.map((place, index) => {
                              return (
                                <Text
                                  variant="small"
                                  noMargin
                                  key={`${index}_${place.object}`}
                                >
                                  {`${place.data.section} - ${place.data.row} - ${place.data.seat}`}
                                </Text>
                              );
                            })}
                          <div className={styles.cart__listItemEdit}>
                            {type.seatmap && (
                              <Button
                                variant="outline"
                                color="primary"
                                size="tiny"
                                onClick={() => {
                                  setActiveShowId(type.show?.id ?? null);
                                  setSeatmapTypeInCartToEdit(type);
                                }}
                              >
                                <RiSofaLine />
                                {t("change_type_places")}
                              </Button>
                            )}

                            {!type.seatmap && (
                              <>
                                <ButtonGroup>
                                  <Button
                                    variant="outline"
                                    color="primary"
                                    size="tiny"
                                    onClick={() =>
                                      setActiveType({
                                        ...type,
                                        fixedPrice: type.price,
                                        showId: type.show?.id,
                                        seatmapId: type.seatmap?.id,
                                      })
                                    }
                                  >
                                    <RiPencilFill />
                                    {t("edit_type")}
                                  </Button>
                                  <Button
                                    variant="outline"
                                    color="error"
                                    size="tiny"
                                    onClick={() => {
                                      setDeleteType(type);
                                    }}
                                    icon
                                  >
                                    <RiDeleteBinLine />
                                  </Button>
                                </ButtonGroup>
                              </>
                            )}
                          </div>
                        </div>
                        <div className={styles.cart__listItemPrice}>
                          <Text variant="h6" component="p" noMargin>
                            {type.price
                              ? formatCurrency(
                                  type.price * type.quantity,
                                  account?.currency as Currency
                                )
                              : t("free")}
                          </Text>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
        </div>
        <div className={cartFooterClass}>
          <Button
            block
            onClick={isCartEmpty(cart) ? undefined : () => setStep("checkout")}
            disabled={isCartEmpty(cart)}
          >
            {t("make_order")}
          </Button>
          <Button
            block
            variant="outline"
            onClick={isCartEmpty(cart) ? undefined : () => setSaveCart(cart)}
            disabled={isCartEmpty(cart)}
          >
            {cart.saved_at ? t("settings_option") : t("make_option")}
          </Button>
          <Button
            block
            variant="link"
            color="error"
            onClick={() =>
              setDeleteCart({
                cartid: cart.cartid,
                saved_at: cart.saved_at ?? null,
              })
            }
          >
            {t("delete")}
          </Button>
        </div>
      </div>

      <button className={styles.cart__toggle} onClick={toggleCart}>
        {<RiShoppingCartLine />}
      </button>

      <EditTypeModal
        activeType={activeType}
        closeModal={() => setActiveType(null)}
      />
      <DeleteTypeFromCartModal
        deleteType={deleteType}
        closeModal={() => setDeleteType(null)}
      />
      <SaveCartModal
        cart={saveCart}
        setCartNull={() => {
          setSaveCart(null);
        }}
        afterSave={() => navigate("/")}
      />
      <CartExpirationModal
        showExpiredCartModal={showExpiredCartModal}
        setShowExpiredCartModal={setShowExpiredCartModal}
      />
    </>
  );
};
