import classNames from "classnames/bind";
import styles from "./ChannelTabs.module.scss";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { ChannelStep } from "@type/channel";

import { ChannelsContext } from "@components/ChannelsWrapper";

interface ChannelTabsProps {
    step: ChannelStep;
    setStep: (step: ChannelStep) => void;
}

const cx = classNames.bind(styles);

export const ChannelTabs = (props: ChannelTabsProps) => {
    const { step, setStep } = props;
    const { cart } = useOutletContext<ChannelsContext>();
    const { t } = useTranslation("components/channel_tabs");

    const listClass = cx("channelTabs__list", {
        "channelTabs__list--withNotes": !!cart && cart.saved_at,
    });

    const buttonClassStore = cx("channelTabs__button", {
        "channelTabs__button--active": step === "store",
        "channelTabs__button--finished": [
            "notes",
            "checkout",
            "confirmation",
        ].includes(step),
    });

    const buttonClassNotes = cx("channelTabs__button", {
        "channelTabs__button--active": step === "notes",
        "channelTabs__button--finished": ["checkout", "confirmation"].includes(
            step
        ),
    });

    const buttonClassCheckout = cx("channelTabs__button", {
        "channelTabs__button--active": step === "checkout",
        "channelTabs__button--finished": ["confirmation"].includes(step),
    });

    return (
        <nav className={styles.channelTabs}>
            <ul className={listClass}>
                <li className={styles.channelTabs__listItem}>
                    <button
                        className={buttonClassStore}
                        onClick={() => setStep("store")}>
                        {t("store")}
                    </button>
                </li>
                {!!cart && cart.saved_at && (
                    <li className={styles.channelTabs__listItem}>
                        <button
                            className={buttonClassNotes}
                            onClick={() => setStep("notes")}>
                            {t("notes")}

                            {/* {!!cart.notes?.length && (
                <>
                  {" "}
                  <Badge content={cart.notes?.length} size="small" />
                </>
              )} */}
                        </button>
                    </li>
                )}
                <li className={styles.channelTabs__listItem}>
                    <button
                        className={buttonClassCheckout}
                        onClick={() => setStep("checkout")}>
                        {t("checkout")}
                    </button>
                </li>
            </ul>
        </nav>
    );
};
