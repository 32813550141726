import { ClickedPlace } from "@components/Seatmap/Seatmap";
import styles from "./SelectPlaceTypeModal.module.scss";
import {
  Button,
  Form,
  FormGroup,
  FormSelect,
  Text,
  Modal,
} from "@eventsquare/uikit";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SelectPlaceTypeModalProps {
  clickedPlaceWithTypes: ClickedPlace | null;
  closeModal: () => void;
  deselectPlace: (placeId: string) => void;
  modifyCart: (
    place: string,
    typeId: string,
    action: "select" | "deselect"
  ) => void;
}

export const SelectPlaceTypeModal = (props: SelectPlaceTypeModalProps) => {
  const {
    // clickedPlace, showModal,
    clickedPlaceWithTypes,
    closeModal,
    deselectPlace,
    modifyCart,
  } = props;

  const [selectedType, setSelectedType] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation("components/select_place_type_modal");

  useEffect(() => {
    if (!clickedPlaceWithTypes) {
      setSelectedType("");
      return;
    }

    setSelectedType(clickedPlaceWithTypes.types[0].id);
  }, [clickedPlaceWithTypes]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!clickedPlaceWithTypes || selectedType === "") return;
    setLoading(true);
    await modifyCart(clickedPlaceWithTypes?.place, selectedType, "select");
    setLoading(false);
    close();
  };

  const close = () => {
    if (loading) return;
    if (clickedPlaceWithTypes) deselectPlace(clickedPlaceWithTypes.place);
    closeModal();
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (loading) return;
    setSelectedType(e.target.value);
  };

  if (!clickedPlaceWithTypes) return null;

  return (
    <Modal isVisible={!!clickedPlaceWithTypes} closeModal={close}>
      <div className={styles.selectPlaceTypeModal}>
        <Form onSubmit={handleSubmit} loading={false}>
          <FormGroup>
            <Text variant="h3" color="info" noMargin>
              {t("title")}
            </Text>
          </FormGroup>
          <FormGroup>
            <FormSelect
              name="selected_type"
              placeholder={t("placeholder")}
              value={selectedType}
              onChange={handleChange}
              options={clickedPlaceWithTypes?.types.map((type) => {
                return {
                  value: type.id,
                  label: type.name,
                };
              })}
            />
          </FormGroup>
          <div>
            <Button block loading={loading}>
              {t("button")}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
